<template>
  <div>
    <iframe src="https://www.kylc.com/huilv/i-visa/usd/cny.html" id="mobsf" scrolling="no" frameborder="0" style="position:absolute;top:115px;left: 215px;right:0px;bottom:100px;"></iframe>
<!--
    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.usEmail"
            placeholder="用户邮箱"
            clearable
        >
        </el-input>
      </el-form-item>

&lt;!&ndash;      <el-form-item>
        <el-select v-model="searchForm.type" placeholder="请选择开卡状态">
          <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>&ndash;&gt;

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="dialogVisible = true" v-if="hasAuth('card:mail:save')">新增</el-button>
      </el-form-item>

&lt;!&ndash;      <el-form-item>
          <el-button type="primary" @click="dialogVisible = true" v-if="hasAuth('shares:ceiling:save')">更新</el-button>
      </el-form-item>&ndash;&gt;
&lt;!&ndash;      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:user:delete')">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>&ndash;&gt;
    </el-form>

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-dblclick	="rowClick"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="50">
      </el-table-column>


      &lt;!&ndash;&ndash;&gt;
      <el-table-column
          prop="superior"
          label="上级或标题"
          width="150">
      </el-table-column>

      <el-table-column
          prop="problemcn"
          label="问题中文"
          width="180">
      </el-table-column>


      <el-table-column
          prop="problemdncn"
          label="问题答案中文"
          width="180">
      </el-table-column>


      <el-table-column
          prop="problemen"
          label="问题英文"
          width="180">
      </el-table-column>
      &lt;!&ndash;&ndash;&gt;
      <el-table-column
          prop="problemdnen"
          label="问题答案英文"
          width="280">
      </el-table-column>

      <el-table-column
          prop="problemdncnt"
          label="问题英文(备用)"
          width="180">
      </el-table-column>
      &lt;!&ndash;&ndash;&gt;
      <el-table-column
          prop="problemdnent"
          label="问题答案英文(备用)"
          width="180">
      </el-table-column>


      <el-table-column
          prop="type"
          label="类型"
          width="150">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.type === '1'" type="success">帮助</el-tag>
          <el-tag size="small" v-if="scope.row.type === '2'" type="success">服务协议</el-tag>
        </template>
      </el-table-column>



      <el-table-column
          prop="icon"
          width="260px"
          label="操作">

        <template slot-scope="scope">

          <el-button type="text" @click="editHandle(scope.row.id)">修改</el-button>


        </template>
      </el-table-column>


    </el-table>

    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :page-sizes="[15, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>


    &lt;!&ndash;新增对话框&ndash;&gt;
    <el-dialog
        title="卡片更新"
        :visible.sync="dialogVisible"
        width="900px"
        :before-close="handleClose">

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
&lt;!&ndash;        <el-form-item label="先查看今天数据是否入库" prop="accountNumber" >

        </el-form-item>&ndash;&gt;
        <el-form-item label="上级或标题"  prop="superior" label-width="100px">
          <el-input v-model="editForm.superior" autocomplete="off"></el-input>
        </el-form-item>
        <el-row>

          <el-col :span="24"><div class="grid-content bg-purple-light">
            <el-form-item label=""  prop="url" label-width="">
              <el-input
                  label="问题中文"
                  type="textarea"
                  :rows="2"
                  placeholder="问题中文"
                  v-model="editForm.problemcn">
              </el-input>
            </el-form-item>
            <el-form-item label=""  prop="url" label-width="">
            <el-input
                label="问题答案中文"
                type="textarea"
                :rows="2"
                placeholder="问题答案中文"
                v-model="editForm.problemdncn">
            </el-input>
            </el-form-item>

            <el-form-item label=""  prop="url" label-width="">
              <el-input
                  label="问题英文"
                  type="textarea"
                  :rows="2"
                  placeholder="问题英文"
                  v-model="editForm.problemen">
              </el-input>
            </el-form-item>

            <el-form-item label=""  prop="url" label-width="">
            <el-input
                label="问题答案英文"
                type="textarea"
                :rows="2"
                placeholder="问题答案英文"
                v-model="editForm.problemdnen">
            </el-input>
            </el-form-item>
            <el-form-item label=""  prop="url" label-width="">
            <el-input
                label="问题英文2"
                type="textarea"
                :rows="2"
                placeholder="问题英文2"
                v-model="editForm.problemdncnt">
            </el-input>
            </el-form-item>
            <el-form-item label=""  prop="url" label-width="">

            <el-input
                label="问答英文2"
                type="textarea"
                :rows="2"
                placeholder="问答英文2"
                v-model="editForm.problemdnent">
            </el-input>
            </el-form-item>



          </div></el-col>
        </el-row>

&lt;!&ndash;
        <el-form-item label=" 开卡状态 1开卡中 2开卡失败 3开卡成功"  prop="type" label-width="100px">
          <el-input v-model="editForm.type" autocomplete="off"></el-input>
        </el-form-item>&ndash;&gt;


        <el-form-item label="类型"  prop="type" label-width="100px">
          <el-radio-group v-model="editForm.type">
            <el-radio :label="'1'">帮助</el-radio>
            <el-radio :label="'2'">服务协议</el-radio>
            &lt;!&ndash;            <el-radio :label="'02'">高级卡</el-radio>
                        <el-radio :label="'03'">白金卡</el-radio>
                        <el-radio :label="'04'">黑金卡</el-radio>
                        <el-radio :label="'05'">免KYC</el-radio>&ndash;&gt;
          </el-radio-group>
        </el-form-item>




      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>




    &lt;!&ndash; 分配权限对话框 &ndash;&gt;
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>
-->

  </div>
</template>
<script>
export default {
  data () {
    return {
    }
  },
  mounted(){
    /**
     * iframe-宽高自适应显示
     */
    function changeMobsfIframe(){
      const mobsf = document.getElementById('mobsf');
      const deviceWidth = document.body.clientWidth;
      const deviceHeight = document.body.clientHeight;
      mobsf.style.width = (Number(deviceWidth)) -215+ 'px'; //数字是页面布局宽度差值
      mobsf.style.height = (Number(deviceHeight))-150 + 'px'; //数字是页面布局高度差
    }

    changeMobsfIframe()

    window.onresize = function(){
      changeMobsfIframe()
    }
  }
}
</script>
<!--
<script>
export default {
  name: "Role",
  data() {
    return {
      options: [{
        value: '',
        label: ''
      },{
        value: '01',
        label: '标准卡'
      }, {
        value: '02',
        label: '高级卡'
      }, {
        value: '03',
        label: '白金卡'
      }, {
        value: '04',
        label: '黑金卡'
      }, {
        value: '05',
        label: '免KYC'
      }],
      searchForm: {},
      delBtlStatu: true,

      total: 0,
      size: 15,
      current: 1,

      dialogVisible: false,
      editForm: {

      },

      tableData: [],

      editFormRules: {
       /* accountNumber: [
          {required: true, message: '请输入iosId', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        secretProtection: [
          {required: true, message: '请输入密保', trigger: 'blur'}
        ]*/
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData:  [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {
    this.getUserList()

    this.$axios.get("/sys/role/list").then(res => {
      this.roleTreeData = res.data.data.records
    })
  },
  methods: {
    rowClick(row,column,event) {
      this.editHandle(row.id)
      console.log(row);///获取该行的所有数据
      console.log(column);
      console.log(event);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },

    getUserList() {
      this.$axios.get("/card/help/listS", {
        params: {
          usEmail: this.searchForm.usEmail,
          type: this.searchForm.type,
          current: this.current,
          size: this.size
        }
      }).then(res => {
        this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total
      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/card/help/' + (this.editForm.id?'update' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/card/help/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);


      this.$axios.post("/sale/help/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });
      })
    },

    roleHandle (id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/user/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/user/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/user/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>

.el-pagination {
  float: right;
  margin-top: 22px;
}

</style>-->
